export default function Svg() {
  return (
    <svg height={32} viewBox="0 0 512 512" width={32}>
      <path
        d="M213,211H0v270.8C0,498.5,13.5,512,30.2,512H241V239C241,223.5,228.5,211,213,211z"
        fill="currentColor"
      />
      <path
        d="M271,359v153h210.8c16.7,0,30.2-13.5,30.2-30.2V331H299C283.5,331,271,343.5,271,359z"
        fill="currentColor"
      />
      <path
        d="M299,301h213V30.2C512,13.5,498.5,0,481.8,0H271v273C271,288.5,283.5,301,299,301z"
        fill="currentColor"
      />
      <path
        d="M241,153V0L30.2,0C13.5,0,0,13.5,0,30.2V181h213C228.5,181,241,168.5,241,153z"
        fill="currentColor"
      />
    </svg>
  )
}
