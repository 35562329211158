import produce from 'immer'
import { ActionType, createReducer } from 'typesafe-actions'
import fetchActions from '../../actions/fetchActions'

type State = number
type Action = ActionType<typeof fetchActions>

const initialState: State = 0

export default createReducer<State, Action>(initialState)
  .handleAction(fetchActions.fetchStartAction, state =>
    produce(state, draft => (draft = state + 1))
  )
  .handleAction(
    [
      fetchActions.fetchEndAction,
      fetchActions.fetchErrorAction,
      fetchActions.fetchCancelAction,
    ],
    state => produce(state, draft => (draft = Math.max(state - 1, 0)))
  )
