import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'
import { FC, useCallback, useState } from 'react'
import { useMount, useUpdateEffect } from 'react-use'
import { useShiftSnack } from '../../core/hooks/useShiftSnack'
import { useSnacksState } from '../../core/hooks/useSnacksState'
import {
  SnackPushMeta,
  SnackPushPayload,
} from '../../core/store/actions/snackActions'

type Snack = { payload: SnackPushPayload; meta: SnackPushMeta }

const SnackLayout: FC = _ => {
  const shift = useShiftSnack()
  const snacks = useSnacksState()
  const [snack, setSnack] = useState<Snack>(snacks[0])
  const [open, setOpen] = useState(false)

  useMount(() => {
    if (snack) setOpen(true)
  })

  useUpdateEffect(() => {
    if (!snacks.length) return

    setSnack(snacks[0])
    setOpen(true)
  }, [snacks.length])

  const handleClose = useCallback(
    (_: React.SyntheticEvent, reason?: string) => {
      if (reason === 'clickaway') return

      setOpen(false)
    },
    []
  )

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      autoHideDuration={snack?.meta.options?.autoHideDuration || 3000}
      onClose={handleClose}
      onExited={shift}
      open={open}
    >
      <Alert
        onClose={handleClose}
        severity={snack?.meta.severity || 'info'}
        variant="filled"
      >
        {snack?.payload.message}
      </Alert>
    </Snackbar>
  )
}

export default SnackLayout
