import { FormikState, getIn } from 'formik'
import { useCallback } from 'react'

export const useHasFieldErrors = (formik: FormikState<unknown>) =>
  useCallback(
    (fields: string[]) =>
      fields.some(
        field => getIn(formik.errors, field) && getIn(formik.touched, field)
      ),
    [formik]
  )
