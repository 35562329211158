import { ReactText } from 'react'
import { IdNameObj, KeyValuePair, Nullable } from '../tools/utilityTypes'

const baseUri = process.env.REACT_APP_API_URI
const version = process.env.REACT_APP_API_VERSION

const concatEndpoint = (path: string) => `${baseUri}/${version}/${path}`

export const apiMap = {
  gfonts: concatEndpoint('gfonts'),
  brands: concatEndpoint('brands'),
  catalogue: concatEndpoint('catalogue'),
  catalogueAttributes: concatEndpoint('catalogue/attributes'),
  components: concatEndpoint('components'),
  compositions: concatEndpoint('compositions'),
  languages: concatEndpoint('languages'),
  locations: concatEndpoint('locations'),
  logout: concatEndpoint('logout'),
  markets: concatEndpoint('markets'),
  self: concatEndpoint('self'),
  showcases: concatEndpoint('virtual-showcases'),
  showcaseAssignComposition: concatEndpoint(
    'virtual-showcases/assignComposition'
  ),
  showcaseAssignVersion: concatEndpoint('virtual-showcases/assignVersion'),
  templates: concatEndpoint('templates'),
  templateTypes: concatEndpoint('templateTypes'),
  dashboard: concatEndpoint('dashboard'),
  versions: concatEndpoint('versions'),
}

export type ApiMapKey = keyof typeof apiMap

export declare namespace SearchType {
  export type Data<F extends string = string, R extends Result = Result> = {
    facets: [{ key: F; options: FacetOption[] }] | []
    results: R[]
  }

  export type FacetOption = {
    count: number
    label: string
    value: ReactText
  }

  export type Facet = {
    key: string
    options: FacetOption[]
  }

  export type Result = { id: number } & KeyValuePair
}

export declare namespace ApiSearchType {
  export type Showcases = SearchType.Data<
    'market' | 'location' | 'status' | 'composition' | 'blocked',
    {
      id: number
      composition: string
      location: string
      market: string
      name: string
      status: string
      blocked: string
    }
  >
  export type Components = SearchType.Data<
    'template' | 'type',
    {
      id: number
      name: string
      slug: string
      template: string
      type: string
    }
  >
}

export declare namespace ApiDataType {
  export namespace Component {
    export namespace JLC {
      type ProductCatalogue = Base & {
        content: {
          assortment: number
          classification: number
          collection: number
          image1: number
          image2: number
        }
        options: {
          filters: string[]
        }
      }

      type HeroBanner = Base & {
        content: {
          desktopImage: number
          laptopImage: number
          tabletImage: number
          mobileImage: number
          title: number
          ctaLabel: number
          ctaLink: number
        }
        options: {
          styles: {
            gradientOpacity: number
          }
        }
      }

      type TextBlock = Base & {
        content: {
          title: number
          subtitle: number
          paragraph: number
        }
        options: {
          textAlign: 'left' | 'center' | 'right'
        }
      }

      type SocialFeed = Base & {
        content: {
          title: number
          subtitle: number
          instagram: string
          links: {
            type: string
            link: number
          }[]
        }
        options: {}
      }

      type FiftyFifty = Base & {
        content: {
          image: number
          title: number
          paragraph: number
          ctaLabel: number
          ctaLink: number
        }
        options: {
          mediaPosition: number
        }
      }

      type CollectionCarousel = Base & {
        content: {
          orderKey: string
          image: number
          title: number
          link: number
        }[]
      }
    }

    export type Sub = {
      parents: {
        id: number
        name: string
        slug: string
      }[]
    }
    export type Base = {
      id: number
      name: string
      slug: string
      template: number
      type: string
    }
    export type Text = Base &
      Sub & {
        content: {
          language: number
          text: string
        }[]
      }
    export type Image = Base &
      Sub & {
        content: {
          src1x: string
          src2x: string
          alt: string
        }
      }
    export type Link = Base &
      Sub & {
        content: {
          isInternal: boolean
          url: string
        }
      }
    export type Video = Base &
      Sub & {
        content: {
          source: number
          url: string
        }
      }
    export type Banner = Base & {
      content: {
        image: number
        title: number
      }
      options: {
        imageSize: number
        titleHue: number
        titleAlignment: [number, number]
      }
    }
    export type ContactForm = Base & {
      content: {
        ctaLabel: number
      }
    }
    export type SocialNetworks = Base & {
      content: {
        title: number
        facebook: number
        instagram: number
        twitter: number
        youtube: number
      }
    }
    export type MediaAndText = Base & {
      content: {
        image: number
        video: number
        title: number
        paragraph: number
        ctaLabel: number
        ctaLink: number
      }
      options: {
        mediaPosition: number
        ratio: {
          width: number
          height: number
        }
      }
    }
    export type TitleParagraph = Base & {
      content: {
        title: number
        paragraph: number
      }
      options: {
        styles: {
          textAlign: number
        }
      }
    }
    export type Catalogue = Base & {
      content: {
        assortment: number
        classification: number
      }
      options: {
        filters: string[]
        productCard: { reference: boolean }
        technicalDetails: {
          key: string
          label: string
          rows: {
            key: string
            label: string
          }[]
        }[]
        overview: {
          reference: boolean
          target: boolean
          collection: boolean
          subCollection: boolean
          shortDescription: boolean
          description: boolean
        }
        editorial: {
          collectionDescription: boolean
          subCollectionDescription: boolean
        }
        ymal: { enabled: boolean }
      }
    }
    export type Logo = Base
    export type GenderFilter = Base & {
      content: {
        manTitle: number
        manImage: number
        womanTitle: number
        womanImage: number
      }
      options: {
        arrangement: number
        manTitleHue: number
        womanTitleHue: number
      }
    }
    export type CollectionFilter = Base & {
      content: {
        collections: {
          reference: string
        }[]
      }
    }
    export type MediaPlayer = Base & {
      content: {
        video: number
      }
      options: {
        youtube: {
          autoplay: boolean
          hideControls: boolean
        }
      }
    }
    export type CollectionMenu = Base & {
      content: {
        orderKey: string
        title: number
        image: number
        link: number
      }[]
    }
    export type Carousel = Base & {
      content: number[]
      options: {
        delay: number
        imageSize: number
      }
    }
  }
  export type Components = Component.Base[]

  export type Self = {
    brand: number
    email: string
    family_name: string
    given_name: string
    name: string
    fonts: Nullable<{
      fontFaces: {
        name: string
        url: string
      }[]
      titleFont: string
      paragraphFont: string
    }>
  }

  export type CategoryValues = {
    assortments: IdNameObj[]
    classifications: IdNameObj[]
  }

  export type CatalogueAttribute = {
    key: string
    label: string
    pimInfo: string
  }

  export type CatalogueAttributes = {
    filters: CatalogueAttribute[]
    technicalDetails: (CatalogueAttribute & { rows: CatalogueAttribute[] })[]
  }

  export type Showcase = {
    composition: Nullable<number>
    language: Nullable<number>
    version: string
    blocked: boolean
    guid: string
    id: number
    location: number
    market: number
    name: string
    showcaseURL: string
    status: number
    comments: string
    websiteURL: string
    contacts: {
      name: string
      email: string
      phoneNumber: string
      type: string
    }[]
  }

  export type Compositions = Composition[]
  export type Composition = {
    guid: string
    id: number
    name: string
    assembly: {
      id: number
      orderKey: string
      page: {
        id: number
        textId: number
        linkId: number
        showInMenu: boolean
        components: {
          id: number
          orderKey: string
        }[]
      }
    }[]
    options: {
      fontFaces: Nullable<
        {
          name: string
          url: string
        }[]
      >
      titleFont: string
      paragraphFont: string
      headerLogo: boolean
      footerLogo: boolean
    }
  }

  export type GFonts = string[]
  export type Brands = (IdNameObj & {
    trigram: string
    appVersion: string
    dms21: boolean
  })[]
  export type Markets = IdNameObj[]
  export type Locations = IdNameObj[]
  export type Languages = IdNameObj[]
  export type Versions = { current: string }
  export type Templates = (IdNameObj & { type: number; slug: string })[]
  export type Dashboard = {
    composition: {
      count: number
    }
    news: {
      title: string
      publishedAt: string
      body: string
      important: boolean
    }[]
    showcase: {
      count: number
      byCountries: {
        count: number
        id: number
        country: string
      }[]
      byMarkets: {
        count: number
        id: number
        market: string
      }[]
      byStatus: {
        count: number
        id: number
        status: string
      }[]
      fiveLastCreated: {
        id: number
        country: string
        name: string
        status: string
      }
      statusHistory: {
        day: string
        blocked: number
        live: number
        to_contact: number
        total: number
        under_discussion: number
        waiting_for_retailer: number
      }[]
    }
  }
}
