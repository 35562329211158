import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    minHeight: 0,
  },
  scroller: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  mb5: {
    marginBottom: theme.spacing(5),
  },
  tab: {
    transition: theme.transitions.create(['color', 'opacity']),
    borderRadius: theme.shape.borderRadius,
    marginRight: theme.spacing(4),
    minHeight: 0,
    minWidth: 0,
    padding: theme.spacing(1, 1),
  },
  denseTab: {
    marginRight: theme.spacing(1),
  },
  errorColor: {
    color: theme.palette.error.main,
    opacity: 1,
  },
  errorBackground: {
    background: `${theme.palette.error.main} !important`,
  },
  tabIndicator: {
    background: theme.palette.primary.main,
  },
  tabArrow: {
    borderRadius: theme.shape.borderRadius,
  },
  disabledTabArrow: {
    opacity: '0.2 !important',
  },
}))
