import Box from '@material-ui/core/Box'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../../components/Button'
import { useAnchorEl } from '../../core/hooks/useAnchorEl'
import { useStyles } from './styles'

type Props = Partial<{
  isDirty: boolean
  isDisabled: boolean
  isSubmitting: boolean
  onDuplicate: () => void
  showMore: boolean
}>

const ComponentActions: FC<Props> = props => {
  const { anchorEl, handleClick, handleClose } = useAnchorEl()

  const { t } = useTranslation()

  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <ButtonGroup>
        {props.showMore && (
          <Button
            disabled={props.isDisabled}
            size="large"
            onClick={handleClick}
            endIcon={<ArrowDropDownIcon />}
          >
            {t('common.more')}
          </Button>
        )}
        <Button
          color="primary"
          disabled={props.isDisabled || !props.isDirty}
          disableElevation
          loading={props.isSubmitting}
          size="large"
          type="submit"
          variant="contained"
        >
          {t('common.save')}
        </Button>
      </ButtonGroup>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            props.onDuplicate && props.onDuplicate()
            handleClose()
          }}
        >
          {t('common.duplicate')}
        </MenuItem>
      </Menu>
    </Box>
  )
}

export default ComponentActions
