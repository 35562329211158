import { combineReducers } from 'redux'
import loading from './loading'
import pool from './pool'
import resources from './resources'
import snacks from './snacks'

export default combineReducers({
  loading,
  pool,
  resources,
  snacks,
})
