import { connectRouter } from 'connected-react-router'
import { History } from 'history'
import { combineReducers } from 'redux'
import admin from './admin'

export const createRootReducer = (history: History) =>
  combineReducers({
    admin,
    router: connectRouter(history),
  })
