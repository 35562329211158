import { makeStyles, Theme } from '@material-ui/core/styles'

type Props = { unclearable?: boolean }

export const useStyles = makeStyles<Theme, Props>(theme => ({
  root: ({ unclearable }) => ({
    alignItems: 'flex-start',
    '& .MuiAutocomplete-clearIndicator': {
      display: unclearable ? 'none' : 'inline-block',
    },
  }),
  createButton: {
    marginLeft: theme.spacing(0.5),
  },
}))
