import qs from 'querystring'
import { useCallback, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { KeyValuePair } from '../tools/utilityTypes'
import { useRouter } from './useRouter'

export const useHashParams = () => {
  const router = useRouter()
  const { hash, search } = useLocation()

  const hashParams = useMemo(
    () => qs.parse(hash.substring(1)) as KeyValuePair,
    [hash]
  )

  const addParam = useCallback(
    (keyValuePair: KeyValuePair) => {
      router.replace({
        search,
        hash: qs.stringify({ ...hashParams, ...keyValuePair }),
      })
    },
    [router, search, hashParams]
  )

  const removeParam = useCallback(
    (key: string) => {
      const newParams = hashParams
      delete newParams[key]

      router.replace({
        search,
        hash: qs.stringify({ ...newParams }),
      })
    },
    [router, search, hashParams]
  )

  return { hashParams, addParam, removeParam }
}
