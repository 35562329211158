import Cookies from 'js-cookie'
import { useCallback, useMemo } from 'react'
import { createStateContext } from 'react-use'

type PaletteType = 'dark' | 'light'

const [
  usePaletteTypeState,
  PaletteTypeProvider,
] = createStateContext<PaletteType>(
  (Cookies.get('prefers_color_scheme') as PaletteType) || 'light'
)

export const usePaletteType = () => {
  const [type, setType] = usePaletteTypeState()

  const togglePaletteType = useCallback(() => {
    setType(type === 'dark' ? 'light' : 'dark')
  }, [type, setType])

  const hasDarkTheme = useMemo(() => type === 'dark', [type])

  return {
    paletteType: type,
    togglePaletteType,
    hasDarkTheme,
  }
}

export { PaletteTypeProvider }
