import { Method } from 'axios'
import qs from 'querystring'
import { getStateFromStore } from '../../tools/getStateFromStore'
import { KeyValuePair } from '../../tools/utilityTypes'

type Args = {
  once?: boolean
  charKey: string
  method: Method
  params?: KeyValuePair
  url: string
}

export const getResource = ({ charKey, method, once, params, url }: Args) =>
  getStateFromStore(store => {
    const resources = store.admin.resources

    return resources[
      resources.findIndex(
        resource =>
          (once || resource.charKey === charKey) &&
          resource.url === url &&
          resource.params === qs.stringify(params) &&
          resource.method === method
      )
    ]?.data
  })
