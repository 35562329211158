import { Method } from 'axios'
import produce from 'immer'
import qs from 'querystring'
import { ActionType, createReducer } from 'typesafe-actions'
import fetchActions from '../../actions/fetchActions'

type Resource = {
  data: any
  charKey: string
  method?: Method
  params?: unknown
  url?: string
}

type State = Resource[]

type Action = ActionType<typeof fetchActions>

const initialState: State = []

export default createReducer<State, Action>(initialState).handleAction(
  fetchActions.fetchEndAction,
  (state, action) =>
    produce(state, draft => {
      const resource = draft.find(
        r =>
          r.charKey === action.meta.charKey &&
          r.url === action.payload.response.config.url &&
          r.method === action.payload.response.config.method &&
          r.params === qs.stringify(action.payload.response.config.params)
      )

      resource && draft.splice(draft.indexOf(resource), 1)

      draft.push({
        data: action.payload.response.data,
        charKey: action.meta.charKey,
        method: action.payload.response.config.method,
        params: qs.stringify(action.payload.response.config.params),
        url: action.payload.response.config.url,
      })
    })
)
