import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    maxHeight: 1024,
    opacity: 0.3,
    position: 'fixed',
    top: '50%',
    transform: 'translateY(-50%)',
    maxWidth: 1024,
    zIndex: -1000,
  },
}))
